.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
}

tr {
  height: 40px !important;
}

th {
  max-width: 125px;
  min-width: 125px;
  padding: 0 !important;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center !important;
}

td {
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
